import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import request from "graphql-request";
import ReactLoading from "react-loading";
import CardComponent from "../utilityComponents/CardComponent";

const CategoriesComponent = () => {
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    const fetchProducts = async () => {
      const { categories } = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                  { 
                   categories {
                        id
                        title
                        titleName
                        description
                        photos {
                          id
                          url
                        }
                        number
                        mainCategoryPhoto{
                        id
                        url
                        }
                      }
                } `,
      );
      return categories;
    };
    fetchProducts().then((categories) => setCategories(categories));
  }, []);

  return (
    <>
      {categories ? (
        <Row className="m-0 d-flex justify-content-start">
          {categories.map((category, i) => {
            const { title, mainCategoryPhoto, titleName, description } =
              categories.find(({ number }) => number === i + 1);
            return (
              <CardComponent
                key={title}
                title={title}
                mainPhoto={mainCategoryPhoto.url}
                slug={titleName}
                description={description}
                name="categories"
              />
            );
          })}
        </Row>
      ) : (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <ReactLoading
            type="bubbles"
            color={"white"}
            height={667 / 2}
            width={375 / 2}
          />
        </div>
      )}
    </>
  );
};

export default CategoriesComponent;
