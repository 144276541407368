import React from "react"
import Layout from "../components/layout"
import loadable from "@loadable/component";
import {Router} from "@reach/router"
import CategoriesComponent from "../components/CategoriesComponents/CategoriesComponent";
import CategoryComponent from "../components/CategoriesComponents/CategoryComponent";
import "./categories.scss"
import {isMobile} from 'react-device-detect';
const AnimatedCursor = loadable(() => import("../components/AnimatedCursor"))

const Categories = () => <Layout>
    {!isMobile && <AnimatedCursor/>}
    <Router>
        <CategoriesComponent path="/categories/"/>
        <CategoryComponent path="/categories/:name"/>
    </Router>
</Layout>


export default Categories
